<template>
  <div>
    <b-card>
      <b-card-header
        class="student-details-card__header position-relative d-flex justify-content-between"
        header-class="p-0 mb-2"
      >
        <div class="student-details-card__title-container d-flex">
          <unicon name="users-alt" width="22" height="22" />
          <h4 class="student-details-card__title ml-1 mb-0">تفاصيل الطالب</h4>
        </div>
        <div
          class="student-details-card__teacher-status-container d-flex align-items-center"
        >
          <div class="student-details-card__teacher-status-title d-flex">
            حالة الاشتراك:
          </div>
          <b-badge pill :variant="studentDto.isSub ? 'success' : 'danger'">
            {{ studentDto.isSub ? " مشترك" : "غير مشترك" }}
          </b-badge>
        </div>
      </b-card-header>
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[{ type: 'required', message: 'اسم الطالب مطلوب' }]"
              name="name"
              placeholder="أدخل اسم الطالب"
              label="اسم الطالب  "
              v-model="studentDto.name"
            ></ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[{ type: 'mobile', message: 'يرجى ادخال رقم الموبايل صحيح' }]"
              name="phoneNumber"
              placeholder="أدخل رقم الموبايل "
              label="رقم الموبايل"
              v-model="studentDto.phoneNumber"
            ></ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              :rules="[{ type: 'email', message: 'يرجى ادخال  بريد الكتروني صحيح' }]"
              name="email"
              placeholder="ادخل البريد الإلكتروني "
              label=" البريد الإلكتروني "
              v-model="studentDto.email"
            ></ek-input-text
          ></b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              name="password"
              placeholder="******** "
              label=" كلمة السر الجديدة"
              v-model="studentDto.password"
            ></ek-input-text>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-date-picker
              name="تاريخ الميلاد "
              placeholder="ادخل تاريخ الميلاد "
              label="تاريخ الميلاد "
              v-model="studentDto.birthdate"
            ></ek-date-picker>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <ek-input-text
              readonly
              name=" class"
              label="الصف"
              v-model="studentDto.class"
            ></ek-input-text>
          </b-col>
          
          <b-col cols="12" md="6" lg="4">
            <div>
              <label class="pb-1" style="font-size: 14px; padding-top: 8px">
                الجنس:</label
              >
              <b-form-radio-group
                v-model="studentDto.gender"
                :options="options"
                value-field="item"
                text-field="name"
              ></b-form-radio-group>
            </div>
          </b-col>

          <div class="d-block">
            <label style="font-size: 14px; margin-top: 7px; margin-right: 13px"
              >المواد المختارة للصف :</label
            >
            <div class="d-flex">
              <b-col lg="4" v-for="item in studentDto.subjects" :key="item.id">
                <b-badge style="padding: 10px; margin-top: 8px" pill variant="success"
                  >{{ item }}
                </b-badge>
              </b-col>
            </div>
          </div>
        </b-row>
      </ValidationObserver>
      <template #footer>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button variant="primary" @click="submit"> تعديل </b-button>
            <b-button variant="outline-primary " class="mx-1" to="/admin/students">
              تراجع
            </b-button>
          </div>
          <div>
            <b-button @click="setIsBlock" variant="outline-danger" class="mr-1"
              >{{ studentDto.isBlocked ? "الغاء الحظر" : "حظر" }}
            </b-button>
          </div>
        </div>
      </template>
    </b-card>
    <ek-table
      :columns="headerDetails"
      no_delete
      @details="unitDetail"
      :items="studentDto.subscriptions"
    >
      <template #table-header>
        <div class="d-flex p-1">
          <unicon class="pr-1" fill="gray" name="vector-square"></unicon>
          <h3>الاشتراكات</h3>
        </div>
        <div>
          <span>
            عدد الاشتراكات :
            <b-badge variant="success"> {{ studentDto.subCount }}</b-badge>
          </span>
        </div>
      </template>

      <template slot="items.endDate" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : "_" }}
      </template>

      <template slot="items.startDate" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : "_" }}
      </template>
    </ek-table>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: {
    id: String,
  },
  data() {
    return {
      options: [
        { item: 1, name: "ذكر" },
        { item: 2, name: "انثى" },
      ],
    };
  },
  computed: {
    ...mapState({
      students: ({ students }) => students.students,
      studentDto: (state) => state.students.studentDto,
      classes: (state) => state.students.classes,
      statusStudentList: (state) => state.students.statusStudentList,
      headerDetails: (state) => state.students.headerDetails,
    }),
    ...mapGetters(["studentList"]),
  },
  methods: {
    ...mapActions([
      "getStudentDetails",
      "updateStudent",
      "blockStudent",
      "unblockStudent",
    ]),
    submit() {
      this.$refs["form"].validate().then((suc) => {
        if (suc) {
          this.updateStudent(this.studentDto);
        }
      });
    },
    setIsBlock() {
      if (!this.studentDto.isBlocked) {
        this.blockStudent(this.id);
      } else this.unblockStudent(this.id);
    },
    unitDetail({ row }) {
      this.$router.push(`/admin/units/${row.id}`);
    },
  },
  created() {
    this.getStudentDetails(this.id);
  },
};
</script>
